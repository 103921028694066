var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"850px","persistent":"","retain-focus":false},model:{value:(_vm.customFieldEditorData.dialog),callback:function ($$v) {_vm.$set(_vm.customFieldEditorData, "dialog", $$v)},expression:"customFieldEditorData.dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t("FORM_INPUT_NAMES.editing_custom_field"))+" ")]),_c('v-btn',{staticClass:"close-button",attrs:{"icon":""},on:{"click":_vm.handleCloseModalForm}},[_c('v-icon',[_vm._v(" mdi-close")])],1)],1)],1)],1),_c('v-card-text',[_c('v-row',[(_vm.customField)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-text-field',{attrs:{"label":"customFieldName"},model:{value:(_vm.customField.customFieldName),callback:function ($$v) {_vm.$set(_vm.customField, "customFieldName", $$v)},expression:"customField.customFieldName"}})],1):_vm._e(),(
            _vm.customField &&
            _vm.customField.config &&
            _vm.customFieldType &&
            _vm.customFieldType.config &&
            _vm.customFieldType.config.label
          )?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-combobox',{attrs:{"items":_vm.translationArray,"return-object":false,"label":"label"},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(_vm.$t(data.item))+" ")]}}],null,false,2750461334),model:{value:(_vm.customField.config.label),callback:function ($$v) {_vm.$set(_vm.customField.config, "label", $$v)},expression:"customField.config.label"}})],1):_vm._e(),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[(_vm.customField)?_c('v-select',{attrs:{"items":_vm.customFieldTypes,"label":"Type","return-object":"","disabled":!_vm.canSelectType},on:{"change":_vm.handleSelectCustomFieldType},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_vm._v(" "+_vm._s(item.config.type)+" ")]}},{key:"selection",fn:function({ item }){return [_c('span',{staticClass:"navi-text"},[_vm._v(_vm._s(item.config.type))])]}}],null,false,2294615777),model:{value:(_vm.customFieldType),callback:function ($$v) {_vm.customFieldType=$$v},expression:"customFieldType"}}):_vm._e()],1)],1),_c('v-row',[(
            _vm.customField &&
            _vm.customField.config &&
            _vm.customFieldType &&
            _vm.customFieldType.config &&
            _vm.customFieldType.config.questionLabel
          )?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-text-field',{attrs:{"label":"questionLabel"},model:{value:(_vm.customField.config.questionLabel),callback:function ($$v) {_vm.$set(_vm.customField.config, "questionLabel", $$v)},expression:"customField.config.questionLabel"}})],1):_vm._e(),(
            _vm.customField &&
            _vm.customField.config &&
            _vm.customFieldType &&
            _vm.customFieldType.config &&
            _vm.customFieldType.config.answerLabel
          )?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-text-field',{attrs:{"label":"answerLabel"},model:{value:(_vm.customField.config.answerLabel),callback:function ($$v) {_vm.$set(_vm.customField.config, "answerLabel", $$v)},expression:"customField.config.answerLabel"}})],1):_vm._e()],1),_c('v-row',[(
            _vm.customField &&
            _vm.customField.config &&
            _vm.customFieldType &&
            _vm.customFieldType.config &&
            _vm.customFieldType.config.height
          )?_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[_c('v-text-field',{attrs:{"label":"height"},model:{value:(_vm.customField.config.height),callback:function ($$v) {_vm.$set(_vm.customField.config, "height", $$v)},expression:"customField.config.height"}})],1):_vm._e(),(
            _vm.customField &&
            _vm.customField.config &&
            _vm.customFieldType &&
            _vm.customFieldType.config &&
            _vm.customFieldType.config.uploadImageMaxWidth
          )?_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[_c('v-text-field',{attrs:{"label":"uploadImageMaxWidth"},model:{value:(_vm.customField.config.uploadImageMaxWidth),callback:function ($$v) {_vm.$set(_vm.customField.config, "uploadImageMaxWidth", $$v)},expression:"customField.config.uploadImageMaxWidth"}})],1):_vm._e()],1),_c('v-row',[(
            _vm.customField &&
            _vm.customField.config &&
            _vm.customFieldType &&
            _vm.customFieldType.config &&
            _vm.customFieldType.config.selectButtonText
          )?_c('v-col',{attrs:{"cols":"12","sm":"5","md":"5"}},[_c('v-text-field',{attrs:{"label":"selectButtonText"},model:{value:(_vm.customField.config.selectButtonText),callback:function ($$v) {_vm.$set(_vm.customField.config, "selectButtonText", $$v)},expression:"customField.config.selectButtonText"}})],1):_vm._e(),(
            _vm.customField &&
            _vm.customField.config &&
            _vm.customFieldType &&
            _vm.customFieldType.config &&
            _vm.customFieldType.config.resolution
          )?_c('v-col',{attrs:{"cols":"12","sm":"2","md":"2"}},[_c('v-text-field',{attrs:{"label":"resolution"},model:{value:(_vm.customField.config.resolution),callback:function ($$v) {_vm.$set(_vm.customField.config, "resolution", $$v)},expression:"customField.config.resolution"}})],1):_vm._e(),(
            _vm.customField &&
            _vm.customField.config &&
            _vm.customFieldType &&
            _vm.customFieldType.config &&
            _vm.customFieldType.config.initialColumnClass
          )?_c('v-col',{attrs:{"cols":"12","sm":"5","md":"5"}},[_c('v-text-field',{attrs:{"label":"initialColumnClass"},model:{value:(_vm.customField.config.initialColumnClass),callback:function ($$v) {_vm.$set(_vm.customField.config, "initialColumnClass", $$v)},expression:"customField.config.initialColumnClass"}})],1):_vm._e()],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.handleCloseModalForm}},[_vm._v(_vm._s(_vm.$t("FORMS.cancel")))]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.handleSaveModalForm}},[_vm._v(" "+_vm._s(_vm.$t("FORMS.save"))+" ")]),_c('v-spacer')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }